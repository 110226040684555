/**
 * 资产管理
 */
import http from '@/http'

export default {
  // 资产清单列表
  getAssetsList(params) {
    return http.get('/asset', {params})
  },
  // 保存资产信息
  saveAssets(params) {
    return http.post('/asset', params)
  },
  // 复制资产信息
  copyAssets(params) {
    return http.post('/asset/copy', params)
  },
  // 删除资产信息
  deleteAssets(params) {
    return http.post('/asset/delete', params)
  },
  getOperateList(params) {
    return http.get('/asset/log', {params})
  },
  // 查询盘点记录
  getAssetsCheck(params) {
    return http.get('/asset/check', {params})
  },
  // 盘点记录详情
  getAssetsCheckDetail(params) {
    return http.get('/asset/check/detail', {params})
  },
  // 工具寿命列表
  getAssetsLife(params) {
    return http.get('/pts', {params})
  },
  // 保存工具寿命信息
  saveTool(params) {
    return http.post('/pts', params)
  },
  // 标记已更换
  signChanged(params) {
    return http.post('/pts/changed', params)
  },
  // 作废
  signInvalid(params) {
    return http.post('/pts/cancel', params)
  },
  // 设置PTS数据频率
  setPTSFrequency(params) {
    return http.post('/pts/data-rate', params)
  },
  // 导入PTS数据
  uploadPTS(params) {
    return http.put('/pts/pts-data', params)
  },
  // 获取非关键工具列表
  getNonkeytoolList(params) {
    return http.get('/nonasset', {params})
  },
  // 保存非关键工具
  saveNonkeyTool(params) {
    return http.post('/nonasset', params)
  },
  // 删除非关键工具
  delNonkeyTool(params) {
    return http.post('/nonasset/delete', params)
  },
  // 查询关联工位列表
  getAssociatedStationList(params) {
    return http.get('/nonasset/ref', {params})
  },
  // 添加关联工位
  saveAssociatedStation(params) {
    return http.post('/nonasset/ref', params)
  },
  // 删除关联工位
  deleteAssociatedStation(params) {
    return http.post('/nonasset/ref/delete', params)
  },
  // 查询点检项列表
  getCheckItemList(params) {
    return http.get(`/nonasset/tpm-items/${params}`)
  },
  // 保存点检项
  saveCheckItem(params) {
    return http.post('/nonasset/tpm-items', params)
  },
  // 删除点检项
  delCheckItem(params) {
    return http.post('/nonasset/tpm-items/delete', params)
  },
  // 复位
  resetAssets(params) {
    return http.post('/asset/reset/current-emp-station', params)
  },
  // 查询点检项列表
  getAssetTpmItems(params) {
    return http.get(`/asset/o2o/tpm`, {params})
  },
  saveAssetTpmItem(params){
    return http.post('/asset/o2o/tpm', params)
  },
  getAssetPtsItems(params){
    return http.get(`/asset/pts-items`, {params})
  },
  saveAssetPtsItems(params){
    return http.post(`/asset/pts-items`, params)
  }
}