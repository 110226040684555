/**
 * 资产管理
 */
import http from '@/http'

export default {
  // TPM 点检数据统计
  getTPM(params) {
    return http.get('/home/statistic/tpm', {params})
  },
  // 资产数据统计
  getStatistics() {
    return http.get('/home/statistic/asset')
  },
  // 报警统计
  getWarnStatistics(params) {
    return http.get('/home/statistic/warn', {params})
  },
  // 预警消息
  getWarnMsg(params) {
    return http.get('/home/message/warn', {params})
  },
  // 预警消息详情
  getWarnMsgDetail(params) {
    return http.get(`/home/message/warn/${params}`)
  },
  // 标记信息为已读
  signHasBeenRead(params) {
    return http.post('/message/v2/read', params)
  }
}