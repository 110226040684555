/**
 * 系统管理
 */
import http from '@/http'

export default {
  // 用户列表
  getUserList(params) {
    return http.get('/user', {params})
  },
  // 获取角色列表
  queryRoles(params) {
    return http.get('/user/roles', {params})
  },
  // 保存用户
  saveUser(params) {
    return http.post('/user', params)
  },
  // 删除用户
  delUser(params) {
    return http.post('/user/delete', params)
  },
  // 修改密码
  updatePwd(params) {
    return http.post('/user/pwd', params)
  },
  // 按类型分页查询字典值
  getDictTypeList({type, ...params}) {
    return http.get(`/dict/${type}`, {params})
  },
  // 保存字典
  saveDict(params) {
    return http.post(`/dict`, params)
  },
  // 删除字典
  delDict(params) {
    return http.post('/dict/delete', params)
  },
  // 查询点检项列表
  getCheckItemList(params) {
    return http.get(`/tpm/item/${params}`)
  },
  // 保存点检项
  saveCheckItem(params) {
    return http.post('/tpm/item', params)  
  },
  // 删除点检项
  delCheckItem(params) {
    return http.post('/tpm/item/delete', params)  
  },
  // 通知公告列表
  getNoticeList(params) {
    return http.get('/notice', {params})
  },
  // 保存通知公告
  saveNotice(params) {
    return http.post('/notice', params)  
  },
  // 发布通知公告
  publishNotice(params) {
    return http.post('/notice/publish', params)  
  },
  // 删除通知公告
  delNotice(params) {
    return http.post('/notice/delete', params)  
  },
  // 获取积分列表
  getIntegralList(params) {
    return http.get('/points/owner', {params})
  },
  // 获取积分明细列表数据
  getIntegralDetailList(params) {
    return http.get('/points/record', {params})
  },
  // 查询积分明细统计信息
  getIntegralDetailCount(params) {
    return http.get('/points/record/total', {params})
  },
  // 员工反馈列表
  getEmployeeFeedbackList(params) {
    return http.get('/feedback', {params})
  },
  // 删除“员工反馈”
  delEmployeeFeedbackList(params) {
    return http.post('/notice/delete', params)  
  },

  // AB套配置列表
  getABSetupList(params) {
    return http.get('/ab-cover-time', {params})
  },
  // 编辑AB套配置
  editABSetup(params) {
    return http.post('/ab-cover-time', params)
  },
  // 设置临时工段
  setTemp(params) {
    return http.post('/ab-cover-time/work-station', params)
  },
  // 删除AB套配置
  delABSetup(params) {
    return http.post('/notice/delete', params)
  },
  getABHandoverPoint(params) {
    return http.get('/config/change-class-time', {params})
  },
  // 设置换班时间点
  setABHandoverPoint(params) {
    return http.post('/config/change-class-time', params)
  }
}