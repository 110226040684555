import axios from 'axios'
import { ElMessage } from 'element-plus'

let login = false
let isFile = false

// 创建axios实例
const instance = axios.create({
  baseURL: window.commonConfigApi,
  // timeout: 1000 * 20,
  withCredentials: true
})

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
 instance.interceptors.request.use(
  config => {
    // 设置Schema标识
    config.headers['WL-Schema'] = 'WEB'

    login = /\/login/i.test(config.url)
    isFile = config.isFile
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，返回给我们对应的状态码，之后我们可以在响应拦截器中根据状态码进行一些统一的操作。
    const token = localStorage.getItem('wlzcToken')
    if (token && !login) {
      config.headers.Authorization = token
    }
    return config
  },
  error => Promise.error(error)
)

// 响应拦截器
instance.interceptors.response.use(
  res => { // 请求成功
    if([20001, 20002, 20003].indexOf(res.data.code) > -1) { // 未登录
      localStorage.setItem('wlzcToken', '')
      let loginUrl = window.location.origin + window.location.pathname + '#/login'
      window.open(loginUrl, '_self')
      return
    }
    if (login || isFile) {
      return res
    } else {
      return res.data
    }
  },
  error => { // 请求失败
    if(error && error.response && error.response.data) {
      ElMessage.error(error.response.data.msg || error.message)
    } else {
      ElMessage.error(error.message)
    }
    return Promise.reject(error)
  }
)

export default instance