/**
 * 登录
 */
import http from '@/http'

export default {
  // 登录
  doLogin(params) {
    return http.post('/login', params)
  },
  // 退出
  // logout() {
  // }
  // 获取用户菜单权限
  getMenuAuth() {
    return http.get('/user/resources')
  }
}