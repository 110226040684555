/**
 * 点检记录
 */
 import http from '@/http'

 export default {
   // 点检记录列表
   getRecordList(params) {
     return http.get('/asset/tpm/record', {params})
   },
   // 点检记录统计信息
   getRecordStatistics(params) {
    return http.get('/asset/tpm/statistics', {params})
  },
  // 作废
  invalidRecord(params) {
    return http.post('/asset/tpm/cancel', params)
  },
  // 查询详情
  getRecordDetail(id) {
    return http.get(`/asset/tpm/record/${id}`)
  },
  // 非关键工具点检记录列表
  getNonkeyRecordList(params) {
    return http.get('/non-asset/tpm/record', {params})
  },
  // 非关键工具点检记录详情
  getNonkeyRecordDetail(id) {
    return http.get(`/non-asset/tpm/record/${id}`)
  }
}