/**
 * 公用接口
 */
import http from '@/http'

// 文件下载function
const downFile  = (data, filename, mime, bom) => {
  var blobData = (typeof bom !== 'undefined') ? [bom, data] : [data]
  var blob = new Blob(blobData, {type: mime || 'application/octet-stream'});
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
    var tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function() {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200)
  }
}

export default {
  // 字典
  queryDict(params) {
    return http.get('/dict', {params})
  },
  // 字典 工段
  queryDictWorkStation() {
    return http.get('/dict/work-station')
  },
  // 字典 工段
  queryVirtualDictWorkStation() {
    return http.get('/dict/work-station/virtual')
  },
  // 字典 工段-班组
  queryWorkStationDictClassGroup() {
    return http.get('/dict/work-and-class')
  },
  // 查询资产类型列表
  queryAssetTypeList(params) {
    return http.get(`/dict/type/${params}`)
  },
  // 文件下载
  downloadFile (url, fileName, params = {}) {
    http.post(url, params, {
      'responseType': 'blob',
      'Content-Type': 'application/octet-stream; application/json;',
      isFile: true
    }).then(res => {
      let dispositionObj = {}
      let disposition = res.headers['content-disposition']
      if(disposition) {
        let dispositionArr = disposition.split(';')
        dispositionArr.forEach(item => {
          let temp = item.split('=')
          dispositionObj[temp[0]] = temp[1]
        })
      }
      if(dispositionObj.filename) {
        dispositionObj.filename = decodeURI(dispositionObj.filename)
      }
      
      let filename = fileName || dispositionObj.filename || ''
      let contentType = res.data && res.data.type
      downFile(res.data, filename, contentType)
    })
  },
  downFile,
  downloadImg (url, fileName) {
    http.get(url, {
      'responseType': 'blob',
      'Content-Type': 'application/octet-stream; image/jpg;',
      isFile: true
    }).then(res => {
      let dispositionObj = {}
      let disposition = res.headers['content-disposition']
      if(disposition) {
        let dispositionArr = disposition.split(';')
        dispositionArr.forEach(item => {
          let temp = item.split('=')
          dispositionObj[temp[0]] = temp[1]
        })
      }
      if(dispositionObj.filename) {
        dispositionObj.filename = decodeURI(dispositionObj.filename)
      }
      
      let filename = fileName || dispositionObj.filename || ''
      let contentType = res.data && res.data.type
      downFile(res.data, filename, contentType)
    })
  },
  // 查询系统消息
  getSysMsg(params) {
    return http.get('/message/v2/system', {params})
  },
  // 标记系统信息为已读
  readSysMsg(params) {
    return http.post('/message/v2/read', params)
  }
}

